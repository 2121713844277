* {
  box-sizing: border-box;
  font-family: monospace;
}

html, body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

#app {
  height: 100%;
}

textarea {
  box-shadow: none;
  border: none;
  outline: none;
}

.ball {
  width: 40px;
  height: 40px;
  float: left;
  background: #0003;
  border-radius: 50%;
  margin: 10px;
}

.makerInput {
  height: 65vh;
  width: 40%;
  float: left;
  color: #444;
  background: #f9f9f9;
  padding: 30px;
  font-size: 16px;
  line-height: 22px;
}

.gameScreen {
  height: 65vh;
  width: 60%;
  float: right;
  color: #54fe54;
  background: #000;
  border-bottom: 3px solid #fff;
  padding: 20px;
  font-size: 18px;
  line-height: 24px;
  overflow-y: auto;
}

.gameMenu {
  display: none;
}

.commandKeeper {
  width: 40%;
  height: 35vh;
  justify-content: center;
  align-items: space-around;
  flex-flow: wrap;
  padding: 40px;
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
}

.playerInput {
  width: 60%;
  float: right;
  height: 17.5vh;
  color: #54fe54;
  background: #000;
  padding: 15px;
  font-size: 18px;
  line-height: 24px;
}

.playerInventory {
  height: 17.5vh;
  color: #54fe54;
  width: 60%;
  float: right;
  background: #000;
  flex-flow: wrap;
  justify-content: space-around;
  align-items: center;
  padding: 15px;
  display: flex;
  position: relative;
}

.playerInventory:before {
  content: "Inventory";
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #aaa;
  font-size: 12px;
  position: absolute;
  top: 5px;
  left: 5px;
}

.gutter {
  background-color: #444;
  background-position: 50%;
  background-repeat: no-repeat;
}

.gutter.gutter-horizontal {
  cursor: ew-resize;
}

.gutter.gutter-vertical {
  cursor: ns-resize;
}

.split, .gutter.gutter-horizontal {
  height: 100%;
  float: left;
}

/*# sourceMappingURL=index.c63714c9.css.map */
