* {
  box-sizing: border-box;
  font-family: monospace;
}

html,
body {
  padding: 0;
  margin: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

$column2: 60%;
$column1: 40%;
$upperArea: 65vh;
$lowerArea: 35vh;

#app {
  height: 100%;
}

textarea {
  border: none;
  outline: none;
  box-shadow: none;
}

.ball {
  width: 40px;
  height: 40px;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  margin: 10px;
  float: left;
}

.makerInput {
  font-size: 16px;
  line-height: 22px;
  height: $upperArea;
  width: $column1;
  float: left;
  padding: 30px;
  background: #f9f9f9;
  color: #444;
}

.gameScreen {
  height: $upperArea;
  width: $column2;
  float: right;
  padding: 20px;
  font-size: 18px;
  line-height: 24px;
  color: #54fe54;
  background: black;
  overflow-y: auto;
  border-bottom: 3px solid white;
}

.gameMenu {
  display: none;
}

.commandKeeper {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 10px;
  width: $column1;
  height: $lowerArea;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: space-around;
  padding: 40px;
}

.playerInput {
  font-size: 18px;
  line-height: 24px;
  background: #e1e1e1;
  padding: 15px;
  width: $column2;
  float: right;
  height: calc($lowerArea/2);
  background: black;
  color: #54fe54;
}

.playerInventory {
  height: calc($lowerArea/2);
  padding: 15px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  position: relative;
  color: #54fe54;
  background: black;
  width: $column2;
  float: right;
  &:before {
    content: "Inventory";
    position: absolute;
    left: 5px;
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: 1px;
    color: #aaa;
    top: 5px;
  }
}

.gutter {
  background-color: #444;
  background-repeat: no-repeat;
  background-position: 50%;
}

.gutter.gutter-horizontal {
  cursor: ew-resize;
}

.gutter.gutter-vertical {
  cursor: ns-resize;
}

.split,
.gutter.gutter-horizontal {
  height: 100%;
  float: left;
}
